import {isUndefined, isBoolean, isNumber} from './utils.js';

const config = {
    __proto__: {
        load
    },

    resourceDebounce: 3000,
    taskDelta: 300,
    lcpMin: 500,
    downloadToRenderDelta: 1000,
    longTask: 60,
    eventDelta: 250,

    ignoreResources: 'cdn_detect,-analytics,perf-measure',

    label: 'wixPerformanceMeasurements',
    fcpPolyfill: 'wixFirstPaint',
    fcpPolyfillId: 'wix-first-paint',
    pageEvent: 'wixPageMeasurements',

    noMeasure: false,
    log: false
};

/**
 * Load configuration from script tag dataSet
 * @param {DOMStringMap} dataset 
 */
function load(dataset) {
    Object.entries(config)
        .map(([key, value]) => [dataset[key], key, value])
        .filter(([data]) => !isUndefined(data))
        .forEach(([data, key, value]) => {
            if (isBoolean(value)) {
                data = true;
            } else if (isNumber(value)) {
                const n = Number(data);
                data = Number.isNaN(n) ? value : n;
            }
            config[key] = data;
        });
}

export default config;
